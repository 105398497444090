import requestAnimationFrame from 'raf';

import Diaporama from 'diaporama';

import diaporamaData from './diaporama.json';

const diaporamaConfig = {
  loop: true,
  autoplay: true,
};

const slideshow = Diaporama(document.getElementById('landing-diaporama'), diaporamaData, diaporamaConfig);

const resizeDiaporama = () =>
  requestAnimationFrame(() => {
    const slideshowContainer = document.getElementById('landing-slideshow');
    const domRect = slideshowContainer.getBoundingClientRect();
    slideshow.width = domRect.width;
    slideshow.height = domRect.height;
  });

window.addEventListener('resize', resizeDiaporama);
resizeDiaporama();